import React from 'react'
import { useLocation } from '@reach/router';

const MetaData = ({ title, description, data, markdownRemark }) => {
  const location = useLocation();

  const isHome = location?.pathname === '/'
  const thumbnail = markdownRemark?.frontmatter?.thumbnail
  const metaDescription = description ?? data?.site.siteMetadata.description ?? markdownRemark?.frontmatter?.description ?? markdownRemark?.excerpt
  
  let pageTitle = title ?? `${markdownRemark?.frontmatter?.title}`

  if(isHome === false) {
    pageTitle += ` — Daniel Immke`
  }

  const metaTags = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: pageTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: markdownRemark ? `article` : `website`,
    },
    {
      name: `author`,
      content: `Daniel Immke`
    }
  ]

  if(thumbnail) {
    metaTags.push({
      name: `image`,
      property: `og:image`,
      content: location.origin + thumbnail
    })
  }
 
  return (
    <>
      <title>{pageTitle}</title>
      {metaTags.map((attrs, idx) => <meta key={idx} {...attrs} />)}
    </>
  )
}

export default MetaData